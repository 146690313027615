import { buildURL } from 'utils/buildUrl'
import ApiService from './ApiService'

const endpoint = '/notifications'

export async function apiCreateNotification(data) {
    return ApiService.fetchData({
        url: `${endpoint}`,
        method: 'post',
        data,
    })
}

export async function apiGetNotifications(queryParams = {}) {
    const url = buildURL(`${endpoint}`, [], queryParams)
    return ApiService.fetchData({
        url: url,
        method: 'get',
    })
}
