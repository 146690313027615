import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function InputPhone ({value, countryCode = 'dz', handleChange, placeholder}) {
	return (
		<PhoneInput
			value={value}
			country={countryCode}
			placeholder={placeholder}
			onChange={handleChange}
			inputStyle={{
				height: '2.75rem',
				width: '100%',
				paddingTop: '1rem',
				paddingBottom: '1rem',
				borderWidth: '1px',
				borderColor: '#cbd5e1',
				borderRadius: '0.375rem'
			}}
		/>
	);
}