import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetNotifications } from 'services/NotificationServices'

export const getNotificationsList = createAsyncThunk(
    'notifications/getNotificationsList',
    async (data) => {
        return await apiGetNotifications(data)
    }
)

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notificationsList: [],
        initialListParams: {
            total: 0,
            pageIndex: 1,
            pageSize: 10,
        },
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
    },
    extraReducers: {
        [getNotificationsList.pending]: (state) => {
            state.loading = true
        },
        [getNotificationsList.fulfilled]: (state, action) => {
            state.consultationsList = action.payload
            state.tableData.total = action.payload.length
            state.loading = false
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken } =
    notificationsSlice.actions

export default notificationsSlice.reducer
